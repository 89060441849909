/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Générez votre 
                    <br />
                    Contrat Intelligent
                  </>
                }
                description="Tout ce dont vous avez besoin pour un commerce international sûr et économique."
              />
              <RotatingCardBack
                image={bgBack}
                title="Déployez votre contrat sur la Blockchain"
                description="Créez facilement votre propre contrat sur le réseau blockchain, sans avoir besoin de connaissances en programmation."
                action={{
                  type: "internal",
                  route: "/services/smart-contract",
                  label: "LANCEZ VOTRE CONTRAT",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Financement commercial simple et sécurisé"
                  description="Sécuriser le paiement des parties en bloquant les fonds dans le contrat intelligent après la signature des parties."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Transactions cryptographiques rapides et à faible coût"
                  description="Des paiements en cryptomonnaie en quelques secondes seulement et à moindre coût."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 0 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Payez si le contrat est exécuté"
                  description="Dans un contrat intelligent, le paiement automatique est effectué à l’achèvement de chaque clause du contrat."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Langage informatique !"
                  description="Les contrats intelligents sont codés dans un langage informatique ; par conséquent, ils ne peuvent pas être interprétés différemment en raison de différences culturelles ou linguistiques."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
